import { GrGithub, GrLinkedinOption } from "react-icons/gr";

function SocialLinks() {
  return (
    <div className="flex gap-5 text-xl">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://github.com/lballanger/"
        className="border-[2px] p-[12px] rounded-full hover:border-primary hover:text-primary transition duration-300"
      >
        <GrGithub />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.linkedin.com/in/loicballanger/"
        className="border-[2px] p-[12px] rounded-full hover:border-primary hover:text-primary transition duration-300"
      >
        <GrLinkedinOption />
      </a>
    </div>
  );
}

export default SocialLinks;
