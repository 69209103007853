import { Outlet } from "react-router-dom";
import Profile from "../components/Profile";
import MobileNavbar from "../components/MobileNavbar";
import NavBar from "../components/NavBar";

const Main = () => {
  return (
    <div className="mx-auto flex">
      <div className="w-[450px] lg:block relative hidden">
        <Profile />
      </div>
      <div className="lg:max-w-[1100px] px-10">
        <Outlet />
      </div>
      <div className="max-w-[95px] lg:block hidden">
        <NavBar />
      </div>
      <div className="lg:hidden block fixed bottom-3 w-full">
        <MobileNavbar />
      </div>
    </div>
  );
};

export default Main;
