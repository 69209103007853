import React from "react";
import ReactDOMClient from "react-dom/client";
import "./index.css";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const rootElement = document.getElementById("root");

const root = ReactDOMClient.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals(sendToVercelAnalytics);
