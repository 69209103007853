import portrait from "../assets/images/portrait.png";
import SocialLinks from "./SocialLinks";

function Profile() {
  return (
    <div className="lg:w-[380px] border-r-[1px] border-[#bbb] px-6 py-10 h-screen sticky top-0">
      <div className="flex justify-around mb-6">
        <h2 className="text-4xl font-medium text-primary">Loïc</h2>
        <h2 className=" leading-[17px]">
          Développeur web
          <br />
          Fullstack JS
        </h2>
      </div>
      <img
        src={portrait}
        alt=""
        className="h-[220px] w-[220px] object-cover rounded-2xl mx-auto grayscale"
      />
      <div className="flex flex-col items-center">
        <h2 className=" text-lg mt-8">ballanger.loic@gmail.com</h2>
        <h2 className=" text-lg mt-2 mb-6">Noisy le Grand, France</h2>
        <SocialLinks />
      </div>
    </div>
  );
}

export default Profile;
