import { lazy } from "react";
import ProjectPage from "../pages/ProjectPage/ProjectPage";

const { createBrowserRouter } = require("react-router-dom");
const { default: Main } = require("../layouts/Main");

const Homepage = lazy(() => import("../pages/Homepage/Homepage"));

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Homepage />,
      },
      {
        path: "/projects",
        element: <ProjectPage />,
      },
    ],
  },
]);
