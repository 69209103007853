import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className="flex justify-center w-full">
      <ul className="menu menu-horizontal bg-base-100 rounded-box border-[1px] border-[#bbb]">
        <li>
          <Link to="/" className="flex flex-col items-center gap-1">
            <lord-icon
              src="https://cdn.lordicon.com/osuxyevn.json"
              target="li"
              trigger="hover"
              class="current-color"
              style={{ width: "24px", height: "24px" }}
            ></lord-icon>
            Accueil
          </Link>
        </li>
        <li>
          <Link to="/projects" className="flex flex-col items-center gap-1">
            <lord-icon
              src="https://cdn.lordicon.com/fpmskzsv.json"
              target="li"
              trigger="hover"
              class="current-color"
              style={{ width: "24px", height: "24px" }}
            ></lord-icon>
            Projets
          </Link>
        </li>
        <li>
          <Link
            to="https://drive.google.com/file/d/19sLJi-N3lJEEvskHOrBy7rVgu0abl1Ew/view?usp=sharing"
            target="_blank"
            className="icon flex flex-col items-center gap-1"
          >
            <lord-icon
              src="https://cdn.lordicon.com/vufjamqa.json"
              target="li"
              trigger="hover"
              class="current-color"
              style={{ width: "24px", height: "24px" }}
            ></lord-icon>
            CV
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
