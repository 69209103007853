const projectsData = [
  {
    name: "Gomoku (en cours)",
    description:
      "Gomoku est un jeu de société classique où l'objectif est d'aligner cinq pièces de sa couleur sur un plateau de jeu. ",
    images: ["https://i.ibb.co/9cQyX1r/pngwing-com.png"],
    features: [
      "Jouer contre un autre joueur en ligne.",
      "Rejoindre une salle de jeu",
      "Créer un compte et se connecter. (soon)",
      "Discuter dans le chat de la salle de jeu et en partie. (soon)",
    ],
    technologies: [
      "React",
      "TypeScript",
      "Redux toolkit",
      "Socket.io",
      "Tailwind CSS",
      "Jest",
      "React testing library",
      "Jwt-decode",
    ],
    client: "https://github.com/Lballanger/gomoku",
    server: "https://github.com/Lballanger/api-gomoku",
    liveLink: "https://gomoku-ands.vercel.app/",
    img: "https://i.ibb.co/9cQyX1r/pngwing-com.png",
  },
  {
    name: "La boutique de textile fait main",
    description:
      "La boutique de textile fait main est une application e-commerce permettant la vente de produits fait main.",
    images: ["https://i.ibb.co/hR1bCky/L-agr-able-utile.png"],
    features: [
      "L'utilisateur peut voir les produits, les détails et les ajouter au panier.",
      "Pour passer commande, l'utilisateur à la possibilité de passer commande en tant qu'invité ou de créer un compte avec son email et mot de passe.",
      "Après avoir passé commande, l'utilisateur peut afficher l'état d'avancement de ses commandes.",
    ],
    technologies: [
      "HTML",
      "CSS",
      "SASS",
      "JavaScript",
      "React JS",
      "Redux toolkit",
      "Node JS",
      "Express",
      "JWT",
      "PostgreSQL",
    ],
    client: "https://github.com/Lballanger/Agreable-utile",
    server: "https://github.com/Lballanger/Agreable-utile",
    liveLink: "https://lagreable-utile-r92cy.ondigitalocean.app/",
    img: "https://i.ibb.co/hR1bCky/L-agr-able-utile.png",
  },
  {
    name: "Back office de La boutique de textile fait main",
    description:
      "Back office de La boutique de textile fait main pour la gestion de l'application e-commerce.",
    images: ["https://i.ibb.co/stg338f/L-agr-able-Utile-1.png"],
    features: [
      "Visualisation des statistiques de de ventes, de produits et de commandes..",
      "Ajouter, modifier, supprimer des articles",
      "Ajouter, modifier, supprimer des catégories",
    ],
    technologies: [
      "HTML",
      "CSS",
      "Material UI",
      "JavaScript",
      "React JS",
      "Redux toolkit",
      "Node JS",
      "Express",
      "JWT",
      "PostgreSQL",
    ],
    client: "https://github.com/Lballanger/Agreable-utile",
    server: "https://github.com/Lballanger/Agreable-utile",
    liveLink: "https://lagreable-utile-admin-arzg3.ondigitalocean.app/",
    img: "https://i.ibb.co/stg338f/L-agr-able-Utile-1.png",
  },

  {
    name: "Evadia",
    description:
      "Évadia est une application permettant la centralisation d'informations concernant des villes.",
    images: ["https://i.ibb.co/YbcmxtG/evadia.png"],
    features: [
      "L'utilisateur à la possibilité d'effectuer des recherches par critères.",
      "Accès à une carte interactive permettant de localiser les villes et de voir les détails de celles-ci.",
      "L'utilisateur peut voir les détails d'une ville et les ajouter à ses favoris.",
    ],
    technologies: [
      "HTML",
      "CSS",
      "SASS",
      "JavaScript",
      "React JS",
      "Zustand",
      "Node JS",
      "Express",
      "JWT",
      "Redis",
      "PostgreSQL",
    ],
    client: "https://github.com/Lballanger/Evadia",
    server: "https://github.com/Lballanger/Evadia",
    liveLink: "https://evadia-e5qod.ondigitalocean.app/",
    img: "https://i.ibb.co/YbcmxtG/evadia.png",
  },
];
export default projectsData;
