import { useState } from "react";

import ProjectCard from "../../components/ProjectCard";
import projectsData from "../../data/projectsData";
import ProjectModal from "../../components/Modals/ProjectModal";

function ProjectPage() {
  const [modal, setModal] = useState([]);

  return (
    <section className="mb-40">
      <h2 className="lg:text-5xl md:text-4xl text-3xl lg:leading-[60px] md:leading-[45px] leading-[36px] max-w-[670px] py-10">
        Mes Projets
      </h2>
      <div className="projects">
        {projectsData.map((project, index) => (
          <ProjectCard
            key={index}
            project={project}
            index={index}
            setModal={setModal}
          />
        ))}
      </div>
      <ProjectModal data={modal} setModal={setModal} />
    </section>
  );
}

export default ProjectPage;
